import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import { LEAD_SCORE_API_BASE_URL } from '@/config'
import Qs from 'qs'

export const leadScoreApi = axios.create({
  baseURL: LEAD_SCORE_API_BASE_URL,
  withCredentials: true,
  crossDomain: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  paramsSerializer (params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export const CancelToken = axios.CancelToken

Vue.prototype.$leadScoreApi = leadScoreApi

leadScoreApi.interceptors.response.use(response => response, error => {
  return Promise.reject((error))
})

// add bearer token to the leadScoreApi requests

leadScoreApi.interceptors.request.use(function (config) {
  const token = `Bearer ${store.state.auth.access_token}`
  if (token) {
    config.headers['Authorization'] = token
  }

  return config
}, function (err) {
  return Promise.reject(err)
})
