import { UserModel } from '@/models/UserModel'

export default [
  {
    path: '/outstanding-calls',
    name: 'calls.outstanding',
    component: () => import('@/views/OutstandingCalls/OutstandingCalls'),
    meta: {
      actions: [UserModel.ACTION_VIEW_OUTSTANDING_CALLS],
    }
  }
]
