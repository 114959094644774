import { UserModel } from '@/models/UserModel'

export default [{
  path: '/sop',
  name: 'sop.index',
  component: () => import('@/views/Sop/Sops'),
  meta: {
    actions: [UserModel.ACTION_EDIT_SOPS],
  }
}, {
  path: '/sop/form/:slug',
  name: 'sop.form',
  component: () => import('@/views/Sop/SopCreateAndEditPage'),
  meta: {
    actions: [UserModel.ACTION_EDIT_SOPS],
  }
}]
