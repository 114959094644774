import { UserModel } from '@/models/UserModel'

export default [
  {
    path: '/critical-properties',
    name: 'critical.properties',
    component: () => import('@/views/Properties/CriticalProperties'),
    meta: {
      actions: [UserModel.ACTION_VIEW_CRITICAL_PROPERTIES],
    }
  }
]
