import { BaseCollection, BaseModel } from '@/models/BaseModel'

export class UserModel extends BaseModel {
  static ROLE_ADMIN = 'Admin';
  static ROLE_AGENT = 'Agent';
  static ROLE_QA = 'Quality Analyst';
  static ROLE_TRAINING = 'Training';

  static ACTION_WILDCARD = '*';

  static ACTION_VIEW_ALL_CALLS = 'VIEW_ALL_CALLS';
  static ACTION_VIEW_OUTSTANDING_CALLS = 'VIEW_OUTSTANDING_CALLS';
  static ACTION_SCORE_CALL = 'SCORE_CALL';

  static ACTION_VIEW_SOPS = 'VIEW_SOPS';
  static ACTION_EDIT_SOPS = 'EDIT_SOPS';

  static ACTION_TRAINING_VIEW_ALL_CALLS = 'TRAINING_VIEW_ALL_CALLS';
  static ACTION_TRAINING_SCORE_CALL = 'TRAINING_SCORE_CALL';

  static ACTION_VIEW_CRITICAL_PROPERTIES = 'VIEW_CRITICAL_PROPERTIES';

  defaults () {
    return {
      id: null,
      first_name: null,
      last_name: null
    }
  }

  me () {
    return this.createRequest({
      url: '/api/v1/me',
      method: 'GET',
      data: {},
    }).send().then(response => {
      this.update(response.getData())
    })
  }

  routes () {
    return {
      fetch: '/api/v1/users/{slug}',
      save: '/api/v1/users',
      delete: '/api/v1/users/{slug}',
      patch: '/api/v1/users/{slug}',
    }
  }

}

export class UserCollection extends BaseCollection {
  model () {
    return UserModel
  }

  routes () {
    return {
      fetch: '/api/v1/users/search'
    }
  }
}
