import Vue from 'vue'
import App from './App'
import './providers/axios'
import router from './router'
import store from './store'
import './providers/filters'
import './providers/raul'
import './providers/acl'

Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.ignoredElements = [/omnibar-*/, /raul-*/]

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
