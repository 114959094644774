import moment from 'moment'
import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'

export const filters = {
  filters: {
    startDate: moment().add('-2', 'weeks').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    search: null,
    status: [/*'active', */'thisWeek', 'twoWeeks'],
    language: 'all',
    critical: 'standard'
  }
}
export default {
  namespaced: true,
  state: () => ({
    scoringQueueFilters: cloneDeep(filters),
    trainingQueueFilters: cloneDeep(filters)
  }),
  getters: {
    scoringQueueFilters: state => {
      return state.scoringQueueFilters
    },
    trainingQueueFilters: state => {
      return state.trainingQueueFilters
    },
  },
  mutations: {
    setFilters (state, payload) {
      const { filters, namespace = 'scoringQueueFilters' } = payload
      if (!filters) {
        throw new Error('Invalid Filter Data')
      }

      Vue.set(state, namespace, filters)
    }
  }
}
