import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '@/config'
import merge from 'lodash/merge'

const module = merge(vuexOidcCreateStoreModule({
  ...oidcSettings
}, {
  namespaced: true,
}), {
  state: {
    leadScoreUser: null,
  }
})

module.mutations.setLeadScoreUser = (state, payload) => {
  state.leadScoreUser = payload
}

export default module
