import Vue from 'vue'
import VueRouter from 'vue-router'
import callRoutes from './routes/calls'
import outstandingCallsRoutes from './routes/outstandingCalls'
import { BASE } from '@/config'
import sop from '@/router/routes/sop'
import properties from '@/router/routes/properties'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: BASE,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }

    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/calls/scoringQueue',
      component: () => import('@/layout/AppLayout'),
      children: [
        ...callRoutes,
        ...outstandingCallsRoutes,
        ...sop,
        ...properties
        // add here authenticated pages
      ]
    },
    {
      path: '/',
      component: () => import('@/layout/SimpleLayout/SimpleLayout'),
      children: [
        {
          path: '/auth-callback',
          name: 'auth.callback',
          component: () => import('@/views/Auth/AuthCallback')
        },
        // add here non authenticated pages
        {
          path: '/logout',
          name: 'auth.logout',
          component: () => import('@/views/Auth/Logout'),
          meta: {
            pageName: 'Logout'
          }
        },
        {
          path: '/forbidden',
          name: 'forbidden',
          component: () => import('@/views/NotFound/Forbidden'),
          meta: {
            pageName: 'Logout'
          }
        },
        {
          // this is the 404. It needs to be the latest in the list
          path: '*',
          name: 'not.found',
          component: () => import('@/views/NotFound/NotFound')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'calls' || to.name === 'calls.score') {
    let callsSource = ['trainingQueue', 'scoringQueue']
    if (callsSource.indexOf(to.params.callsSource) !== -1) {
      return next()
    } else {
      return next({ name: 'not.found' })
    }
  }
  return next()
})

export default router
