// https://vuemc.io/#basic-usage
import { Collection, Model, Request, RequestError, Response } from 'vue-mc'
import { CancelToken, leadScoreApi } from '@/providers/axios'
import merge from 'lodash/merge'
import Vue from 'vue'

class EntityResponse extends Response {
  getData () {
    return this.response.data.data
  }
}

class CollectionResponse extends Response {
  getData () {
    return this.response.data.data
  }
}

class BaseRequest extends Request {
  send () {
    return leadScoreApi.request(this.config).then((response) => {
      return new EntityResponse(response)
    }).catch((error) => {
      throw new RequestError(error, new Response(error.response))
    })
  }
}

class CollectionRequest extends Request {
  constructor (config) {
    super(config)
    this.cancelTokenCallback = null
  }

  setCancelTokenCallback (callback) {
    this.cancelTokenCallback = callback
  }

  send () {
    const cancelToken = CancelToken.source()

    if (this.cancelTokenCallback) {
      this.cancelTokenCallback(cancelToken, this)
    }

    return leadScoreApi.request({
      ...this.config,
      cancelToken: cancelToken.token,
    }).then((response) => {
      return new CollectionResponse(response)
    }).catch((error) => {
      throw new RequestError(error, new Response(error.response))
    })
  }
}

export class BaseModel extends Model {
  createRequest (config) {
    return new BaseRequest(config)
  }
}

export class BaseCollection extends Collection {
  constructor () {
    super()
    this._page = 1
    this._perPage = 25
    this.meta = {
      current_page: 1,
      per_page: this._perPage,
      total: 0
    }
    this.append = false
    this.cancelToken = null
  }

  createRequest (config) {
    const request = new CollectionRequest(config)

    request.setCancelTokenCallback((cancelToken, request) => {
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      this.cancelToken = cancelToken
    })

    return request
  }

  onFetchSuccess (response) {
    if (!this.append) {
      this.clear()
    }

    Vue.set(this, 'meta', response.response.data.meta)
    return super.onFetchSuccess(response)
  }

  options () {
    return {
      methods: {
        fetch: 'POST'
      }
    }
  }

  getPaginationQuery () {
    return {
      page: this._page,
      limit: this._perPage
    }
  }

  isLastPage () {
    return false
  }

  fetch () {
    var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}
    if (typeof args.append !== 'undefined') {
      this.append = args.append
    }

    var config = () => {
      return {
        url: this.getFetchURL() || args.url,
        method: this.getFetchMethod() || args.method,
        params: merge(this.getFetchQuery(), args.params),
        headers: merge(this.getFetchHeaders(), args.headers),
        data: args.data
      }
    }
    return this.request(config, this.onFetch, this.onFetchSuccess, this.onFetchFailure)
  }
}
